@import "/src/styles/mixin";

.wrap {
  padding-top: 90px;
  padding-bottom: 90px;
  text-align: center;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.title {
  @include font30;
  margin-bottom: 32px;
}

.text {
  @include font14;
  max-width: 948px;
  margin: 0 auto 30px;
}

.subtitle {
  @include font24;
  margin-bottom: 24px;
}

.list {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px 40px;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    max-width: 450px;
    margin: 0 auto 34px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
    text-align: left;
    gap: 10px 45px;
  }
}

.item {
  @include font14;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  &::before {
    content: "✔";
    margin-right: 12px;
  }
}

.link {
  @include font14;
  display: inline-block;
  position: relative;
  padding: 12px 24px;
  border: 1px solid var(--black-color);
  transition:
    color 0.2s ease,
    background 0.2s ease;
  border-radius: var(--border-radius-small);
  &:hover {
    color: var(--white-color);
    background-color: var(--black-color);
  }
  &::after {
    content: "→";
    margin-left: 12px;
  }
}
